import search from "../../assets/images/search.svg"
import "./searchInputBox.css"
import React, { useState } from 'react';
import closeIcon from "../../assets/images/closeIcon.svg"

function SearchBox({ placeholder, type, onSearch, onClear }) {
    const [searchText, setSearchText] = useState('');

    const handleSearchChange = (e) => {
        const newText = e.target.value;
        setSearchText(newText);
        onSearch(newText); // Notify parent component about the search text
        // setShowCloseButton(searchText !== '');
    };
    const handleClearInput = () => {
        setSearchText(''); // Clear the search text
        onClear(); // Notify parent component about clearing the input
    }
    return (
        <div className="searchBoxInputField">
            <input
                style={{ background: '#fff', marginLeft: "10px" }}
                placeholder="Search"
                required
                value={searchText}
                onChange={handleSearchChange}
                type="text"
            />

            {searchText !== '' ? (
                <img src={closeIcon} alt="Cross icon" className="cross-icon" onClick={handleClearInput} />
            ) : (
                <img src={search} alt="Search icon" className="search-icon" />
            )}


        </div>
    );
}

export default SearchBox;
