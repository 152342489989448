import "../mod1/mod1.css"
import InputField1 from "../InputField1";
import Button1 from "../uploadButton/Button1";
import closeIcon from "../../assets/images/closeIcon.svg"

function Mod2(props) {
    return (
        // <div style={{
        //     position: 'fixed',
        //     width: '100%',
        //     height: '100%',
        //     top: 0,
        //     left: 0,
        //     right: 0,
        //     bottom: 0,
        //     backgroundColor: 'rgba(0,0,0,0.8)',
        //     zIndex: 1
        // }}>
        <div style={{
            position: 'fixed',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            zIndex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <div className="Mod2-parent-container">
                {props.closeModal ?
                    <button className="Mod1-parent-container-content-close-icon" aria-label="close-icon" onClick={props.closeModal}>
                        <img src={closeIcon}></img>
                    </button> : null}
                <span className="Mod1-parent-container-content2 my-3">{props.text['primaryText']}</span>

                {props.inputBox ?
                    <div className="Mod2-parent-container-content4">
                        <InputField1 placeHolder={""} label={props.inputBox.label} type={"text"} style={{ margin: "0 0 2rem 0", background: "#2E375B", width: "24.75rem" }} value={props.value} setValue={props.setValue} />
                    </div> : null}

                <div className="Mod2-parent-container-content5">
                    {props.confirmButton ? <Button1
                        text={props.confirmButton.text}
                        borderRadius={"10px"}
                        background={"linear-gradient(90deg, #F7707D 0%, #F29173 100%)"}
                        color={"#ffffff"}
                        onClick={props.confirmButton.onClick}
                        style={{ marginBottom: "0px", padding: "3px", marginTop: "2rem" }}
                    /> : null
                    }
                </div>
            </div>
        </div>);
}

export default Mod2;