import "../deviceDeblessed/deblessedUpload.css"
import React, { Component } from 'react';
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import AuthService from "../../Auth/auth";
import { DotPulseLoader } from "freespace-react-components"
import timer from "../../assets/images/timer.svg"
import refresh from "../../assets/images/refresh.svg"
import download from "../../assets/images/download.svg"
import upload from "../../assets/images/upload.svg"
import updownarrow from "../../assets/images/updownArrows.svg"
import downloadArrow from "../../assets/images/downloadArrow.svg"
import line from "../../assets/images/line.svg"
import search from "../../assets/images/search.svg"
import downArrow from "../../assets/images/downArrow.svg"
import freespaceIcon from "../../assets/images/pngs/logo.png"
import SearchBox from "../../components/searchInputBox/searchInputBox";
import SaveButton from "../../components/saveButton/saveButton";
import FileDrop from "../../components/fileDrop/fileDrop";
import Button1 from "../../components/uploadButton/Button1";
import CheckBox from "../../components/checkBox/checkBox";
import Mod1 from "../../components/mod1/Mod1";
import Mod2 from "../../components/mod2/Mod2"
import alertIcon from "../../assets/images/alert.svg";
import '../../css/space-planner.css';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { Border, SelectionButtons, DropDownArrowButton } from "freespace-react-components";

function Approver(props) {
    //set states, use useeffects, create functions....
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState([]);
    const [showFileDropComponent, setShowFileDropComponent] = useState(true);
    const [showDownloadDropdown, setShowDownloadDropdown] = useState(false);
    const [showChecklist, setShowChecklist] = useState(false);
    const [spaces, setSpaces] = useState({});
    const [loading, setloading] = useState(false);
    const [showDeblessedPopUP, setShowDeblessedPopUp] = useState(false)
    const [loginKeyPopUp, setLoginKeyPopUp] = useState(false)
    const [modalPopUp, setModalPopUp] = useState(false);
    const fileDropRef = useRef(null);
    const dropdownRef = useRef(null);
    const [login, setLogin] = useState();
    const [storeBlessMacFromFile, setStoreBlessMacFromFile] = useState([])
    const [selectedCategories, setSelectedCategories] = useState(['All']);
    const [handleEmptyPopUp, setHandleEmptyPopUp] = useState(false);
    const [showDeblessBtn, setShowDeblessBtn] = useState(false);
    const [showAddNew, setShowAddNew] = useState(false);
    const [addNewDevice, setAddNewDevice] = useState();
    const [addNewValue, setAddNewValue] = useState([]);
    const [storeType, setStoreType] = useState();
    const [filteredSpaces, setFilteredSpaces] = useState({});


    const initialSortDirections = {};
    // Initialize sort directions for each column
    Object.keys(spaces).forEach((key) => {
        initialSortDirections[key] = 'asc';
    });

    const [sortDirections, setSortDirections] = useState(initialSortDirections);

    const modalDetails = {
        secondaryText: "The Space to work efficiently",
        icon: freespaceIcon,
        inputBox: true,
        confirmButtonText: "Get Started"
    }

    let searchAPIResponse = {}

    const readExcelDataAndPopulate = async (blessIDAndMacID) => {
        let type;
        const validBlessIds = [];
        const validMacIds = [];
        const wrongIdInput = [];
        let invalidBlessMacIds = [];
        setStoreBlessMacFromFile(blessIDAndMacID)

        if (blessIDAndMacID) {
            blessIDAndMacID.forEach(item => {
                const itemString = String(item);
                if (itemString.length === 10) {
                    const intValue = parseInt(item, 10);
                    if (!isNaN(intValue)) {
                        validBlessIds.push(intValue);
                    }
                    else {
                        wrongIdInput.push(item);
                    }

                } else if (item.length === 16 && typeof item === 'string') {
                    validMacIds.push(item);
                }
                else {
                    wrongIdInput.push(item);
                }
            });
            if (validBlessIds.length > 0) {
                type = 'blessId';
                setStoreType(type);
                searchAPIResponse = await AuthService.searchAPIModified(validBlessIds, type);
                let responseDeviceIds = searchAPIResponse.map(item => item?.device?.blessId);
                invalidBlessMacIds = validBlessIds.filter(blessId => !responseDeviceIds.includes(blessId));
            }
            else if (validMacIds.length > 0) {
                type = 'macId';
                setStoreType(type);
                searchAPIResponse = await AuthService.searchAPIModified(validMacIds, type);
                let responseDeviceIds = searchAPIResponse.map(item => item?.device?.hardware?.deviceId);
                invalidBlessMacIds = validMacIds.filter(macId => !responseDeviceIds.includes(macId));
            }
            const allInvalidIds = [...invalidBlessMacIds, ...wrongIdInput];
            // console.log(validBlessIds, validMacIds, allInvalidIds);

            const apidataObject = {};






            // const dbResponseObj = {};    // Read from db  // fetch
            // //call db read api here like searchAPIResponse
            // const entries = Object.entries(searchAPIResponse);
            // const promises = entries.map(async ([key, value]) => {
            //     dbResponseObj[value.device.blessId] = {
            //         installId: value.id,
            //         blessId: value.bless_id,
            //         macId: value.hardware_device_id,
            //         requester_id: value.requester_id,
            //         requester_comments: value.requester_comments,
            //         requested_at: value.requested_at,
            //         status: value.status,
            //         reviewer_id: value.reviewer_id,
            //         reviewer_comments: value.reviewer_comments,
            //         reviewed_at: value.reviewed_at,
            //     }
            // });
            // await Promise.all(promises);
            // setSpaces(dbResponseObj);
            // // setloading(false)




            try {
                setShowFileDropComponent(false);
                const entries = Object.entries(searchAPIResponse);
                const promises = entries.map(async ([key, value]) => {
                    const extractedContent = value?.device?.hardware?.deviceId;
                    let summaryApiData = await AuthService.summaryApi(extractedContent);    // Summary API takes macId in the URL of API
                    const devicegroupIdValue = summaryApiData?.response?.data?.relationships?.devicegroup?.id;
                    const summaryProductId = summaryApiData?.response?.data?.relationships?.product?.id;

                    let productIdData = await AuthService.productType(summaryProductId);    // Product API gives type of product by passing productId obtained from summary API
                    let deviceIdData = await AuthService.modelName(devicegroupIdValue);     // Model API gives model name by passing deviceId obtianed from summary API

                    apidataObject[value.device.blessId] = {
                        productType: productIdData?.response?.data?.attributes?.name || "--",
                        accountName: value.account?.name || "--",
                        installId: value.device?.id || "--",
                        blessId: value.device?.blessId || "--",
                        macId: value?.device?.hardware?.deviceId || "--",
                        floorId: value.location?.id || "--",
                        floorStatus: value.location?.showInMenu ? "Live" : "Unlive",
                        space: value.spaces[0]?.id || "--",
                        modelName: deviceIdData?.response?.data?.attributes?.name || "--",
                        deviceType: deviceIdData?.response?.data?.type === "production_devicegroup" ? "Production" : (deviceIdData?.response?.data?.type) === "development_devicegroup" ? "Development" : (deviceIdData?.response?.data?.type) === "factoryfixture_devicegroup" ? "Factory" : "--",
                        status: deviceIdData?.response?.data?.type === "production_devicegroup" ? "Blessed" : (deviceIdData?.response?.data?.type) ? "Unblessed" : "Invalid",
                        deviceGroupId: summaryApiData?.response?.data?.relationships?.devicegroup?.id,
                        bgColor: deviceIdData?.response?.data?.type === "production_devicegroup" ? "#748ADF" : (deviceIdData?.response?.data?.type) ? "#667799" : "#BBBBBB",
                    };
                });

                if (allInvalidIds.length > 0) {
                    allInvalidIds.forEach(item => {
                        apidataObject[item] = {
                            productType: "--",
                            accountName: "--",
                            installId: "--",
                            blessId: typeof item == 'number' ? item : "--",
                            macId: typeof item == 'string' ? item : "--",
                            floorId: "--",
                            floorStatus: "--",
                            space: "--",
                            modelName: "--",
                            deviceType: "--",
                            status: "Invalid",
                            deviceGroupId: "--",
                            bgColor: "#BBBBBB",
                        };
                    })
                }
                if (validBlessIds.length === 0 && validMacIds.length === 0 && allInvalidIds.length === 0) {
                    setShowFileDropComponent(false)
                    setHandleEmptyPopUp(true)
                }

                setloading(true);

                await Promise.all(promises);
                setSpaces(apidataObject);
                setFilteredSpaces(apidataObject);
                setloading(false)

            } catch (error) {
                setloading(false);
                console.error("Error fetching and processing data:", error);
            }
        }

        else {
            setShowFileDropComponent(false)
            setHandleEmptyPopUp(true)
        }
    };

    //Check the no of only selected devices
    const spacesLength = Object.entries(isCheck).length;
    const liveStatusCount = Object.entries(filteredSpaces).filter(([data, blessId]) => isCheck.includes(data)).reduce((count, [data, blessId]) => {   // To check the devices assigned on Live floor
        if (blessId.floorStatus === "Live") {
            return count + 1;
        }
        return count;

    }, 0);

    const loginKey = (value) => {
        setLogin(value)
    }

    const addNewBtnFunctionality = () => {

        if (storeType) {
            setShowFileDropComponent(false)
            setShowAddNew(true)
        }
        else {
            setShowFileDropComponent(true)
            setShowAddNew(false)
        }


    }

    const newDevice = (value) => {
        setAddNewValue(value)
    }

    const addNewFunction = () => {
        const newValues = addNewValue.split(',').map(value =>
            value.trim().replace(/-/g, '')
        );

        const validValues = newValues.filter(value => {
            if ((storeType === "blessId" && value.length === 10) ||
                (storeType === "macId" && value.length === 16)) {
                return true;
            } else {
                alert(
                    storeType === "blessId"
                        ? "Bless ID should be 10 characters long."
                        : "Mac ID should be 16 characters long."
                );
                return false;
            }
        });

        if (validValues.length > 0) {
            setShowAddNew(false);
            const combinedArray = [...storeBlessMacFromFile, ...validValues];
            readExcelDataAndPopulate(combinedArray);
        }

    }

    const deblessedBtnFunctionality = () => {
        if (Object.keys(filteredSpaces).length > 0) {

            if (isCheck.length == 0) {
                window.alert("Please select entries that you want to debless")
            }
            else {
                //Display First Modal
                setShowDeblessedPopUp(true)
            }
        }
    }

    const deblessedAPICallingFromMacAddress = async () => {
        setLogin('');
        setLoginKeyPopUp(false);
        setloading(true);
        const promises = Object.entries(spaces)
            .filter(([data, blessId]) => isCheck.includes(data))
            .map(async ([data, blessId]) => {
                let deblessedData = await AuthService.deblessedAPI(login, blessId.macId, blessId.deviceGroupId);
                if (deblessedData.response == "OK") {
                    setloading(false)
                    Object.entries(spaces)
                        .filter(([data, blessId]) => isCheck.includes(data))
                        .map(async ([data, blessId]) => {
                            blessId.status = "Deblessed"
                            blessId.bgColor = "#4ECB71"
                        });
                    setIsCheck([]);
                    setIsCheckAll(false)
                }
                else if (deblessedData.response.errors[0].status === '403') {
                    setloading(false)
                    setModalPopUp(true);
                }
                else {
                    setloading(false)
                    Object.entries(spaces)
                        .filter(([data, blessId]) => isCheck.includes(data))
                        .map(async ([data, blessId]) => {
                            blessId.status = "Failed"
                            blessId.bgColor = "#FF5E5E"
                        });
                    setIsCheck([]);
                    setIsCheckAll(false)

                }
            });
    }

    useEffect(() => {
        if (isCheckAll) {
            setShowDeblessBtn(true);
            setIsCheck(Object.entries(filteredSpaces).filter(([data, blessId]) => (blessId.deviceType == "Production")).map(([data, blessId]) => (data)))
        } else {
            setIsCheck([]);
            setShowDeblessBtn(false);
        }
    }, [isCheckAll]);

    const handleSelectAll = (e) => {
        setIsCheckAll(!isCheckAll);
    };

    const handleClick = (e) => {
        const { id, checked } = e.target;
        setIsCheck([...isCheck, id]);
        setShowDeblessBtn(true);
        if (!checked) {
            setIsCheck(isCheck.filter((item) => item !== id));
            setShowDeblessBtn(false);
        }
    };

    const handleFunc = (e) => {
        const { id, checked } = e.target;

        if (checked) {
            if (id === 'All') {
                setSelectedCategories(['All']);
            } else {
                setSelectedCategories((prevSelected) => {
                    const updatedSelected = prevSelected.includes('All') ? prevSelected.filter(category => category !== 'All') : prevSelected;
                    return [...updatedSelected, id];
                });
            }
        } else {
            setSelectedCategories((prevSelected) => prevSelected.filter(category => category !== id));
        }
    };
    const toggleRefresh = () => {
        if (storeBlessMacFromFile.length > 0) {
            readExcelDataAndPopulate(storeBlessMacFromFile);
            setSelectedCategories(['All']);
        }
    }
    const toggleDropDown = () => {
        setShowDownloadDropdown(true)
    }
    const toggleChecklist = () => {
        setShowChecklist(true)
    }

    const handleSearch = (searchText) => {

        if (searchText === '') {
            setFilteredSpaces(spaces); // Restore original data

        } else {
            const filteredData = Object.values(spaces).filter((blessId) => {
                return Object.values(blessId).some((value) => {
                    const lowerSearchText = searchText.toLowerCase();
                    if (typeof value === 'string') {
                        return value.toLowerCase().includes(lowerSearchText);
                    } else if (typeof value === 'number') {
                        return value.toString().includes(lowerSearchText);
                    }
                    return false;
                });
            });

            setFilteredSpaces(filteredData);

            if (filteredData.length === 0) {
                // Display an alert or take any other action for no matching results
                alert('No matching results found');
            }
        }
    };
    const handleClearSearch = () => {
        setFilteredSpaces(spaces)
    }

    const sortData = (column) => {
        const newSortDirections = { ...sortDirections };
        newSortDirections[column] = newSortDirections[column] === 'asc' ? 'desc' : 'asc';
        setSortDirections(newSortDirections);

        const spacesArray = Object.values(spaces); // Convert data object to array to apply sort function.

        const sorted = [...spacesArray].sort((a, b) => {
            const aValue = a[column];
            const bValue = b[column];


            if (aValue === '--' && bValue === '--') {
                return 0; // Treat -- values as equal
            }

            if (aValue === '--') {
                return newSortDirections[column] === 'asc' ? 1 : -1; // -- values after non --
            }

            if (bValue === '--') {
                return newSortDirections[column] === 'asc' ? -1 : 1; // -- values after non --
            }

            if (typeof aValue === 'string') {
                return newSortDirections[column] === 'asc'
                    ? aValue.toLowerCase() < bValue.toLowerCase()
                        ? -1
                        : 1
                    : aValue.toLowerCase() > bValue.toLowerCase()
                        ? -1
                        : 1;
            } else if (typeof aValue === 'number') {
                return newSortDirections[column] === 'asc' ? aValue - bValue : bValue - aValue;
            }

            return 0; // Default case if types are not string or number
        });
        setFilteredSpaces(sorted);
    };

    useEffect(() => {  // reference for download list hiding
        const handleDocumentClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowDownloadDropdown(false);
            }
        };
        document.addEventListener('click', handleDocumentClick);
        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, []);

    useEffect(() => {  // reference for checklist div hiding
        const handleDocumentClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowChecklist(false);
            }
        };

        document.addEventListener('mousedown', handleDocumentClick);

        return () => {
            document.removeEventListener('mousedown', handleDocumentClick);
        };
    }, []);


    const handleDownload = (format) => {
        // Prepare table data
        const tableData = [...document.querySelectorAll('#deviceTable tr')].map(row =>
            [...row.children].map(cell => cell.textContent)
        );
        const tableEmptyCheck = [...document.querySelectorAll('#deviceTable td')]
        const formattedDateTime = `${String(new Date().getDate()).padStart(2, '0')}-${String(new Date().getMonth() + 1).padStart(2, '0')}-${new Date().getFullYear()} ${String(new Date().getHours()).padStart(2, '0')}:${String(new Date().getMinutes()).padStart(2, '0')}:${String(new Date().getSeconds()).padStart(2, '0')}`;

        let content;
        if (format === 'csv') {
            content = tableData.map(row => row.join(',')).join('\n');
            if (tableEmptyCheck.length == 0) {
                alert.window = "Please Upload the file";
            }
            else {
                const blob = new Blob([content], { type: 'text/csv;charset=utf-8' });
                saveAs(blob, `device_list_${formattedDateTime}.${format}`);
            }

        } else if (format === 'xlsx') {
            const sheetName = 'Sheet1';
            let data1 = tableData ? tableData.map(d1 => Object.values(d1)) : [];
            let newData1 = data1.map(d1 => Object.values(d1));
            let headers1 = tableData && tableData.length > 0 ? Object.keys(tableData[0]) : [];
            const worksheet = XLSX.utils.aoa_to_sheet([]);
            let aoa = [];

            newData1.forEach(a => {
                if (a != null) {
                    aoa.push([...a.map(d1 => d1 != null ? d1.toString() : '')]);
                }
            });

            const newworksheet = XLSX.utils.sheet_add_aoa(worksheet, aoa, { origin: 0 });
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, newworksheet, sheetName);
            if (data1.length > 1) {
                XLSX.writeFile(workbook, `device_list_${formattedDateTime}.${format}`);

            } else {
                alert.window = "Please Upload the file";
            }
        }
    };

    return (
        <>
            <div className="headerPrimary">
                <p>Device List</p>
            </div>
            <Border />
            {/* <div className="headerSecondary" style={{ width: '50%', paddingLeft: '6.85rem' }}>
                <img src={timer} alt="clock"></img>
                <p className="secondaryText">Session Time:</p>
                <p className="secondaryText">10:00</p>
            </div>
            <Border /> */}

            <div className="headerTernary">
                <div className="searchPanel">
                    <SearchBox
                        onSearch={handleSearch}
                        onClear={handleClearSearch}
                    />
                    <div className="mainBodyFilter">
                        <p onClick={toggleChecklist} style={{ cursor: "pointer" }}>Show Only</p>
                        <img src={downArrow} className="downArrow" style={{ cursor: "pointer" }} onClick={toggleChecklist} ></img>
                        {showChecklist && (
                            <div className={`dropdownDivChecklist ${showChecklist ? 'show' : ''}`} ref={dropdownRef}>
                                <ul className="dropdown-menu-checklist"><b>Show Only</b>
                                    <li><CheckBox id={"All"} handleClick={handleFunc} isChecked={selectedCategories.includes('All')} /> All</li>
                                    <li><CheckBox id={"Blessed"} handleClick={handleFunc} isChecked={selectedCategories.includes('Blessed')} /> Blessed</li>
                                    <li><CheckBox id={"Unblessed"} handleClick={handleFunc} isChecked={selectedCategories.includes('Unblessed')} /> Unblessed</li>
                                    <li><CheckBox id={"Invalid"} handleClick={handleFunc} isChecked={selectedCategories.includes('Invalid')} /> Invalid</li>
                                    <li><CheckBox id={"Failed"} handleClick={handleFunc} isChecked={selectedCategories.includes('Failed')} /> Failed</li>
                                </ul>
                            </div>
                        )
                        }
                        {/* <SaveButton /> */}
                    </div>
                </div>
                <div className="handlingButtons">
                    <img src={refresh} alt="RefreshBtn"
                        // onClick={() => {
                        //     readExcelDataAndPopulate(storeBlessMacFromFile);
                        //     setSelectedCategories(['All']);New
                        // }} 
                        onClick={toggleRefresh}
                        style={{ cursor: "pointer" }}></img>
                    <Border />
                    <div className="downloadBtnAndArrow" onClick={toggleDropDown} ref={dropdownRef}>
                        <img src={download} alt="DownloadBtn"></img>
                        <img src={line}></img>
                        <img src={downloadArrow} alt="DownloadBtnArrow"></img>
                        {showDownloadDropdown && (
                            <div className="dropdownDiv">
                                <ul className="dropdown-menu">
                                    <li onClick={() => handleDownload('csv')}>CSV</li>
                                    <li onClick={() => handleDownload('xlsx')}>Excel</li>
                                </ul>
                            </div>
                        )
                        }


                    </div>
                    {/* <img src={upload} alt="UploadBtn"></img> */}

                    <Button1
                        text={"Add New"}
                        style={{ marginBottom: "0", textColor: "white", borderRadius: "8px", width: "6.3rem", minHeight: "38px" }}
                        background={"linear-gradient(90deg, rgb(247, 112, 125) 100%, rgb(242, 145, 115) 100%)"} color={"#ffffff"}
                        onClick={addNewBtnFunctionality}
                    />
                    {showDeblessBtn ?
                        <Button1
                            text={"Debless"}
                            style={{ marginBottom: "0", textColor: "white", borderRadius: "8px", width: "6.3rem", minHeight: "38px" }}
                            background={"linear-gradient(90deg, rgb(247, 112, 125) 100%, rgb(242, 145, 115) 100%)"} color={"#ffffff"}
                            onClick={deblessedBtnFunctionality}
                        /> : null
                    }

                </div>


            </div >
            <div className="excelDataBlock">
                <table class="table" id="deviceTable">
                    <thead>
                        <tr>
                            <th>Product Type<img src={updownarrow} onClick={() => sortData('productType')}></img></th>
                            <th>Account<img src={updownarrow} onClick={() => sortData('accountName')}></img></th>
                            <th>Install ID<img src={updownarrow} onClick={() => sortData('installId')}></img></th>
                            <th>Bless ID<img src={updownarrow} onClick={() => sortData('blessId')}></img></th>
                            <th>Mac ID<img src={updownarrow} onClick={() => sortData('macId')}></img></th>
                            <th>Floor ID <img src={updownarrow} onClick={() => sortData('floorId')}></img></th>
                            <th>Floor Status <img src={updownarrow} onClick={() => sortData('floorStatus')}></img></th>
                            <th>Space <img src={updownarrow} onClick={() => sortData('space')}></img></th>
                            <th>Model Name <img src={updownarrow} onClick={() => sortData('modelName')}></img></th>
                            <th>Device Type <img src={updownarrow} onClick={() => sortData('deviceType')}></img></th>
                            <th>Status <img src={updownarrow} onClick={() => sortData('status')}></img></th>
                            <th><CheckBox
                                // onCheck={(value) => console.log("first selection", value)}
                                handleClick={handleSelectAll}
                                isChecked={isCheckAll}
                            /></th>





                            {/* <th>Install ID <img src={updownarrow} onClick={() => sortData('installId')}></img></th>
                            <th>Bless ID<img src={updownarrow} onClick={() => sortData('blessId')}></img></th>
                            <th>Mac ID <img src={updownarrow} onClick={() => sortData('macId')}></img></th>
                            <th>Requester ID<img src={updownarrow} onClick={() => sortData('requesterName')}></img></th>
                            <th>Requester Comments<img src={updownarrow} onClick={() => sortData('requesterComment')}></img></th>
                            <th>Requested At <img src={updownarrow} onClick={() => sortData('createdAt')}></img></th>
                            <th>Status<img src={updownarrow} onClick={() => sortData('status')}></img></th>
                            <th>Reviewer ID<img src={updownarrow} onClick={() => sortData('approvedBy')}></img></th>
                            <th>Reviewer Comments<img src={updownarrow} onClick={() => sortData('approvedAt')}></img></th>
                            <th>Reviewed At <img src={updownarrow} onClick={() => sortData('deviceType')}></img></th> */}




                            {/* <th>Status <img src={updownarrow} onClick={() => sortData('status')}></img></th> 
                            <th><CheckBox
                                // onCheck={(value) => console.log("first selection", value)}
                                handleClick={handleSelectAll}
                                isChecked={isCheckAll}
                            /></th> */}
                        </tr>
                    </thead>

                    <tbody id="users-data-body">
                        {loading && (<div className="loader"><DotPulseLoader /></div>)}
                        {Object.entries(filteredSpaces)
                            .filter(([data, blessId]) => (
                                selectedCategories.includes('All') || selectedCategories.includes(blessId.status)))
                            .map(([data, blessId]) => (
                                <tr key={blessId} id="users-data-row">
                                    <td>{blessId?.productType}</td>
                                    <td>{blessId?.accountName}</td>
                                    <td>{blessId?.installId}</td>
                                    <td>{blessId?.blessId}</td>
                                    <td><div class="text-wrap">{blessId?.macId}</div></td>
                                    <td>{blessId?.floorId}</td>
                                    <td>{blessId?.floorStatus}</td>
                                    <td>{blessId?.space}</td>
                                    <td><div class="text-wrap">{blessId?.modelName}</div></td>
                                    <td><div class="text-wrap">{blessId?.deviceType}</div></td>
                                    <td>
                                        <div class="statusCol" style={{ background: blessId.bgColor, borderRadius: "20px", padding: "0.2rem 0.5rem", color: "white", height: "2rem", display: "flex", justifyContent: "center", alignItems: "center", width: "5rem" }}>
                                            {blessId?.status}
                                        </div>
                                    </td>
                                    <td><CheckBox
                                        // key={blessId.blessId}
                                        id={data}
                                        handleClick={handleClick}
                                        isChecked={isCheck.includes(data)}
                                        disabled={blessId.status == "Blessed" ? false : true}
                                    /></td>
                                </tr>
                            ))}

                        {/* .filter(([data, blessId]) => (
                                selectedCategories.includes('All') || selectedCategories.includes(blessId.status))) 



                            .map(([data, blessId]) => (   // fetch
                                <tr key={blessId} id="users-data-row">
                                    <td>{blessId?.installId}</td>
                                    <td>{blessId?.blessId}</td>
                                    <td>{blessId?.macId}</td>
                                    <td>{blessId?.requester_id}</td>
                                    <td><div class="text-wrap">{blessId?.requester_comments}</div></td>
                                    <td>{blessId?.requested_at}</td>
                                    <td>{blessId?.status}</td>
                                    <td>{blessId?.reviewer_id}</td>
                                    <td><div class="text-wrap">{blessId?.reviewer_comments}</div></td>
                                    <td><div class="text-wrap">{blessId?.reviewed_at}</div></td> 



                                    <td>
                                        <div class="statusCol" style={{ background: blessId.bgColor, borderRadius: "20px", padding: "0.2rem 0.5rem", color: "white", height: "2rem", display: "flex", justifyContent: "center", alignItems: "center", width: "5rem" }}>
                                            {blessId?.status}
                                        </div>
                                    </td>
                                    <td><CheckBox
                                        // key={blessId.blessId}
                                        id={data}
                                        handleClick={handleClick}
                                        isChecked={isCheck.includes(data)}
                                        disabled={blessId.status == "Blessed" ? false : true}
                                    /></td> */}
                    </tbody>

                </table>
                {showDeblessedPopUP ?
                    <div className="col-10 col-sm-6 col-lg-6 mx-auto">
                        <Mod1 text={{ primaryText: "Debless Device", secondaryText: `${liveStatusCount} out of ${spacesLength} devices are assigned on Live Floor. Do you still want to debless it?`, icon: alertIcon }}
                            closeModal={() => setShowDeblessedPopUp(false)}
                            confirmButton={{
                                text: "Yes", onClick: () => {
                                    setShowDeblessedPopUp(false)
                                    setLoginKeyPopUp(true);
                                }
                            }}
                        />
                    </div>
                    : null
                }
                {loginKeyPopUp ?
                    <div className="col-10 col-sm-6 col-lg-6 mx-auto">
                        <Mod1 text={{ secondaryText: modalDetails.secondaryText, icon: freespaceIcon }}
                            // closeModal={() => setGetNumberToTest(false) }
                            confirmButton={{
                                text: modalDetails.confirmButtonText, onClick: deblessedAPICallingFromMacAddress
                            }}
                            inputBox={modalDetails.inputBox}
                            value={modalDetails.inputBox ? login : null}
                            setValue={modalDetails.inputBox ?
                                loginKey
                                : null}
                        />
                    </div>
                    : null
                }
                {modalPopUp ?
                    <div className="col-10 col-sm-6 col-lg-6 mx-auto">
                        <Mod1 text={{ primaryText: "Invalid Credentials", secondaryText: "Please enter valid login key", icon: alertIcon }}
                            // closeModal={() => setModalPopUp(false)}
                            confirmButton={{
                                text: "Try Again", onClick: () => {
                                    setModalPopUp(false)
                                }
                            }}
                        />
                    </div>
                    : null
                }
                {handleEmptyPopUp ?
                    <div className="col-10 col-sm-6 col-lg-6 mx-auto">
                        <Mod1 text={{ primaryText: "Invalid File", secondaryText: "Please enter valid CSV/Excel file", icon: alertIcon }}
                            // closeModal={() => setModalPopUp(false)}
                            confirmButton={{
                                text: "Okay", onClick: () => {
                                    setHandleEmptyPopUp(false)
                                    setShowFileDropComponent(true)
                                }
                            }}
                        />
                    </div>
                    : null
                }
                {showAddNew ?
                    <div className="addnewDiv">
                        <Mod2
                            text={{ primaryText: "Add New Device" }}
                            closeModal={() => setShowAddNew(false)}
                            confirmButton={{
                                text: "Done", onClick:
                                    addNewFunction

                            }}
                            inputBox={{
                                label: `Enter ${storeType === 'macId' ? "Mac ID" : "Bless ID"}:`
                            }}
                            value={modalDetails.inputBox ? addNewValue : null}
                            setValue={modalDetails.inputBox ?
                                newDevice
                                : null}
                        />
                    </div>
                    : null
                }
                <div className="uploadingDiv">
                    {showFileDropComponent && (
                        <FileDrop
                            ref={fileDropRef}
                            onClick={readExcelDataAndPopulate}
                        />
                    )}
                </div>


            </div >
        </>
    );
}

export default Approver;