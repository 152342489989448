import "./mod1.css"
import InputField1 from "../InputField1";
import Button1 from "../uploadButton/Button1";
// import infoIcon from "../../assets/images/infoIcon.svg"
import loginKeyIcon from "../../assets/images/loginKey.svg"
import closeIcon from "../../assets/images/closeIcon.svg"

function Mod1(props) {
    return (
        <div style={{
            position: 'fixed',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0,0,0,0.8)',
            zIndex: 1
        }}>
            <div className="Mod1-parent-container">
                {props.closeModal ?
                    <button className="Mod1-parent-container-content-close-icon" aria-label="close-icon" onClick={props.closeModal}
                    >
                        <img src={closeIcon}></img>
                    </button> : null}
                <img src={props.text.icon} className="Mod1-parent-container-content1"></img>
                <span className="Mod1-parent-container-content2 my-3">{props.text['primaryText']}</span>
                <span className="Mod1-parent-container-content3 my-2">{props.text['secondaryText']}</span>

                {props.inputBox ?
                    <div className="Mod1-parent-container-content4">
                        <InputField1 logo={loginKeyIcon} placeHolder={"Enter Login Key"} label={"Login Key:"} type={"password"} style={{ margin: "0 0 2rem 0" }} value={props.value} setValue={props.setValue} />

                    </div> : null}

                <div className="Mod1-parent-container-content5">
                    {props.confirmButton ? <Button1
                        text={props.confirmButton.text}
                        borderRadius={"10px"}
                        background={"linear-gradient(90deg, #F7707D 0%, #F29173 100%)"}
                        color={"#ffffff"}
                        onClick={props.confirmButton.onClick}
                        style={{ marginBottom: "0px", padding: "3px" }}
                    /> : null
                    }
                    {props.closeModal ? <Button1
                        text={"Cancel"}
                        borderRadius={"10px"}
                        background={"transparent"}
                        style={{ border: "1px solid #FF9777", marginLeft: "1rem", marginBottom: "0px" }}
                        color={"#FF9777"}
                        onClick={props.closeModal}
                    /> : null}
                </div>
            </div>
        </div>);
}

export default Mod1;