import React, { useState } from 'react';
import './checkBox.css';

function Checkbox({onChange, handleClick, isChecked, id, disabled }) {
    // const [isChecked, setIsChecked] = useState(checked || false);

    // const handleCheckboxChange = (event) => {
    //     const newCheckedValue = event.target.checked;
    //     // setIsChecked(newCheckedValue);

    //     // Call the parent component's onChange callback if provided
    //     if (typeof onChange === 'function') {
    //         onChange(newCheckedValue);
    //     }
    // };

    return (
        <label>
            <input
                type="checkbox"
                id={id}
                onChange={handleClick}
                checked={isChecked}
                disabled={disabled}
                style={{ width: "20px", height: "20px", color: "#62CC71" }}
            />
            {/* <span className={`custom-checkbox ${isChecked ? 'checked' : ''}`}></span> */}
            {/* {label} */}
        </label>
    );
};

export default Checkbox;
// function Checkbox({ id, type, name, handleClick, isChecked }) {
//     return (
//         <input
//             id={id}
//             name={name}
//             type={type}
//             onChange={handleClick}
//             checked={isChecked}
//         />
//     );
// };
// export default Checkbox;