import React from "react";

function Button1({ text, borderRadius, background, color, onClick, style }) {

    const buttonStyle = {
        fontWeight: "700",
        fontSize: "12px",
        background: background,
        borderRadius: borderRadius,
        color: color,
        border: "transparent",
        width: "100%",
        minHeight: "38px",
        ...style
    }
    return (

        // <div className="col-md-8">
        <button style={buttonStyle} onClick={onClick ? onClick : (e) => e.preventDefault()} >
            {text}
        </button>
        // </div>
    );
}

export default Button1;